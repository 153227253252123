import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { useDevice } from 'services/hooks';
import { Modal } from 'common/layout';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';

import { LandingPagePopUpForm } from './components';
import {
  Container, ExtendedHeading, ButtonContainer, ModalHeader, CloseIcon, ContentContainer,
  ContainerBackgroundImage, ContentInnerContainer,
} from './styled';

export const LandingPagePopUp: React.FC<LandingPagePopUpProps> = ({
  layoutData, isOpen, closeModal,
}) => {
  const {
    popUpTitle, popUpDescription, popUpLinkButtonUrl, popUpLinkButtonText, popUpCallToActionIsForm,
    popUpImageMobile, popUpImageDesktop, popUpName, popUpFormName, popUpFormFirstInputTitle,
    popUpFormSecondInputTitle, popUpFormSendButtonText, popUpFormSuccessMessage,
  } = layoutData || {};
  const { isDesktop } = useDevice();
  const { navigate } = useI18next();
  const popUpImage = isDesktop ? popUpImageDesktop : popUpImageMobile;

  const mobileVariant = {
    open: { x: 0 },
    closed: { x: '-100%' },
  };

  const desktopVariant = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  const animationVariant = isDesktop ? desktopVariant : mobileVariant;

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCenter>
      <Container
        key="Landing Page popup"
        animate={isOpen ? 'open' : 'closed'}
        initial="open"
        variants={animationVariant}
        popUpCallToActionIsForm={popUpCallToActionIsForm}
      >
        <ModalHeader>
          <CloseIcon onClick={closeModal} />
        </ModalHeader>
        {popUpImage?.file?.url && (
          <ContainerBackgroundImage src={popUpImage?.file?.url} alt={`Image pop up ${popUpName}`} />
        )}
        <ContentContainer>
          <ContentInnerContainer>
            {popUpTitle && <ExtendedHeading as="h2">{popUpTitle}</ExtendedHeading>}
            {popUpDescription && (
              <Text $lineHeight="20">{popUpDescription}</Text>
            )}
            {!popUpCallToActionIsForm ? (
              <>
                {popUpLinkButtonUrl && (
                  <ButtonContainer>
                    <Button
                      variant="CTA"
                      {...(popUpLinkButtonUrl.includes('http')
                        ? { href: popUpLinkButtonUrl, target: '_blank' }
                        : { onClick: () => navigate(popUpLinkButtonUrl) }
                      )}
                    >
                      {popUpLinkButtonText || 'Confirm'}
                    </Button>
                  </ButtonContainer>
                )}
              </>
            ) : (
              <LandingPagePopUpForm
                {...{
                  popUpFormName,
                  popUpFormFirstInputTitle,
                  popUpFormSecondInputTitle,
                  popUpFormSendButtonText,
                  popUpFormSuccessMessage,
                  closeModal,
                }}
              />
            )}
          </ContentInnerContainer>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

type LandingPagePopUpProps = {
  layoutData: GatsbyTypes.GeneralPageQuery['contentfulPageGeneral'];
  isOpen: boolean;
  closeModal: () => void;
};
