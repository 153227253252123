import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { getLocalData, setLocalData } from 'services';
import { useDevice, useAuthenticationUser, useSelector } from 'services/hooks';
import { selectActiveContract, selectAmountContracts } from 'selectors';
import { Template } from 'common/layout';
import { ContentArea } from 'common/contentarea';
import { LandingPagePopUp } from 'modules/LandingPagePopUp';

const GeneralPage: React.FC<GeneralPageProps> = ({ data }) => {
  const { determined: authDetermined, authenticated } = useAuthenticationUser();
  const membership = useSelector(selectActiveContract);
  const amountOfMemberships = useSelector(selectAmountContracts);
  const layoutData = data?.contentfulPageGeneral;
  const body = layoutData?.body as i.ContentAreaBlocksTypes[] || [];
  const meta = {
    title: layoutData?.title,
    description: layoutData?.metaDescription,
    pathname: layoutData?.slug,
    locale: data.locales.edges?.[0].node.language,
  };
  const [isLandingPagePopUpOpen, setIsLandingPagePopUpOpen] = React.useState(false);
  const hasUserSeenPopUp = getLocalData(`user::seenPopUp-${layoutData?.popUpName}`);
  const showPopUpOn = layoutData?.showPopUpOn;
  const showPopUpTo = layoutData?.showPopUpTo;
  const { isDesktop, determined: deviceDetermined } = useDevice();
  const shouldShowPopUpOn = (showPopUpOn === 'mobile only' && !isDesktop)
        || (showPopUpOn === 'desktop only' && isDesktop)
        || showPopUpOn === 'both'
        || !showPopUpOn;

  const shouldShowPopUpTo = () => {
    if (showPopUpTo === 'everyone' || !showPopUpTo) return true;

    if (showPopUpTo === 'non-member') {
      // can be not logged in user or logged in user without membership
      if (!authenticated) return true;
      if (authenticated && amountOfMemberships === 0) return true;
    }

    if (showPopUpTo === 'gold + premium member') {
      if (membership) return true;
    }

    if (showPopUpTo === 'gold member') {
      // only gold members that are not premium.
      if (membership && !membership.seriesName.toLowerCase().includes('premium')) return true;
    }

    if (showPopUpTo === 'premium member') {
      // only premium gold members
      if (membership && membership.seriesName.toLowerCase().includes('premium')) return true;
    }

    return false;
  }

  React.useEffect(() => {
    if (layoutData?.showPopUp && !hasUserSeenPopUp && deviceDetermined && authDetermined) {
      if (shouldShowPopUpOn && shouldShowPopUpTo()) {
        setTimeout(() => {
          setIsLandingPagePopUpOpen(true);
        }, 3000);
      }
    }
  }, [layoutData?.showPopUp, deviceDetermined, authDetermined, amountOfMemberships])

  const onCloseModal = () => {
    setIsLandingPagePopUpOpen(false);

    if (!hasUserSeenPopUp) {
      setLocalData(`user::seenPopUp-${layoutData?.popUpName}`, 'true');
    }
  };

  return (
    <Template meta={meta}>
      {layoutData?.showPopUp && (
        <LandingPagePopUp
          isOpen={isLandingPagePopUpOpen}
          layoutData={layoutData}
          closeModal={onCloseModal}
        />
      )}
      <ContentArea contentTypes={body} />
    </Template>
  );
};

export default GeneralPage;

export const query = graphql`
  query GeneralPage($id: String, $language: String) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulPageGeneral(id: {eq: $id}, node_locale: {eq: $language}) {
      title
      metaDescription
      slug
      body {
        __typename
        ...PageBodyFragment
      }
      showPopUp
      showPopUpOn
      showPopUpTo
      popUpTitle
      popUpDescription
      popUpCallToActionIsForm
      popUpLinkButtonUrl
      popUpLinkButtonText
      popUpName
      popUpImageMobile {
        file {
          url
        }
      }
      popUpImageDesktop {
        file {
          url
        }
      }
      popUpFormName
      popUpFormFirstInputTitle
      popUpFormSecondInputTitle
      popUpFormSendButtonText
      popUpFormSuccessMessage
    }
  }
`;

export const pageBodyFragment = graphql`
  fragment PageBodyFragment on Node {
    ...AccordionFragment
    ...BannerFragment
    ...CarouselFragment
    ...CtaButtonFragment
    ...FilteredScheduleFragment
    ...FormBlockFragment
    ...ImageBlockFragment
    ...InstagramGridFragment
    ...ReviewCarouselFragment
    ...PricingBlockFragment
    ...PricingAdvancedBlockFragment
    ...HeroHeaderFullHeightFragment
    ...HeroHeaderHalfHeightFragment
    ...HeroHeaderVideoModalFragment
    ...HighlightImageLinksFragment
    ...HolyFriendsFragment
    ...InstagramGridFragment
    ...SpotifyEmbedFragment
    ...TextBlockFragment
    ...TextColumnsFragment
    ...UspBlockFragment
    ...VideoblockFragment
  }
`;

type GeneralPageProps = {
  data: GatsbyTypes.GeneralPageQuery;
};
