import * as i from 'types';
import * as React from 'react';

import { useForm } from 'react-hook-form';
import { graphql } from 'gatsby';

import { Input } from 'common/form';
import { Heading } from 'common/typography';
import { postForm } from 'ducks/forms';
import { useDispatch, useDevice } from 'services/hooks';
import { validation, triggerGtagEvent, configureImageSrc } from 'services';
import { Button } from 'common/interaction';

import { FormContainer, ButtonContainer } from './styled';

export const LandingPagePopUpForm = ({
  popUpFormName, popUpFormFirstInputTitle, popUpFormSecondInputTitle, popUpFormSendButtonText,
  popUpFormSuccessMessage, closeModal,
}: LandingPagePopUpForm) => {
  const { isDesktop } = useDevice();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = (data: LandingPagePopUpFormData) => {
    const formattedData: i.ComponentFormBlockData = {
      name: data.popUpFormFirstInputTitle,
    };

    if (data.popUpFormSecondInputTitle) {
      formattedData.emailaddress = data.popUpFormSecondInputTitle;
    }

    dispatch(postForm(formattedData, popUpFormName || 'Landing Page form', true))
      .then(() => {
        reset();
        alert(popUpFormSuccessMessage);
        closeModal();
      }).catch(() => {
        alert('something went wrong, please try again later');
      });
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('popUpFormFirstInputTitle', { ...validation.required })}
        label={popUpFormFirstInputTitle}
        error={errors.popUpFormFirstInputTitle}
        variant="light"
      />
      {popUpFormSecondInputTitle && (
        <Input
          {...register('popUpFormSecondInputTitle', { ...validation.required })}
          label={popUpFormSecondInputTitle}
          error={errors.popUpFormSecondInputTitle}
          variant="light"
        />
      )}
      <ButtonContainer>
        <Button size={isDesktop ? 'auto' : 'small'} type="submit" variant="CTA">
          {popUpFormSendButtonText || 'Send'}
        </Button>
      </ButtonContainer>
    </FormContainer>
  )
}

type LandingPagePopUpForm = {
  popUpFormName?: string;
  popUpFormFirstInputTitle: string;
  popUpFormSecondInputTitle?: string;
  popUpFormSendButtonText?: string;
  popUpFormSuccessMessage?: string;
  closeModal: () => void;
};

type LandingPagePopUpFormData = {
  popUpFormFirstInputTitle: string;
  popUpFormSecondInputTitle?: string;
};
