import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/utils';

import Close from 'vectors/Close.svg';
import { Heading } from 'common/typography';

export const Container = styled(motion.div)`
  align-content: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  min-height: 50vh;
  margin: 24px;
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'image';

  & >:nth-child(1) {
    grid-area: header;
  }

  & >:nth-child(2) {
    grid-area: image;
  }

  & >:nth-child(3) {
    grid-area: content;
  }

  ${media.desktop<ContainerProps>`
    width: ${({ popUpCallToActionIsForm }) => popUpCallToActionIsForm ? '70vw' : '40vw'};
    grid-template-areas:
      'image header'
      'image content';
    grid-template-columns: 1fr 1fr;
    align-content: normal;
    grid-template-rows: 60px 1fr;
  `}

  ${media.large<ContainerProps>`
    width: ${({ popUpCallToActionIsForm }) => popUpCallToActionIsForm ? '55vw' : '40vw'};
  `}
`;

type ContainerProps = {
  popUpCallToActionIsForm?: boolean;
};

export const ExtendedHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.black};
  line-height: 30px;
  margin-top: 0;

  ${media.desktop`
    line-height: 40px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  margin-top: 32px;

  ${media.desktop`
    flex-direction: row;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  padding: 24px 24px 8px 24px;
  justify-content: flex-end;
  height: 100%;

  ${media.desktop`
    padding: 24px 24px 0 0;
  `}
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

  ${media.desktop`
    justify-content: center;
  `}
`;

export const ContentInnerContainer = styled.div`
  padding: 0 24px 32px 24px;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    padding: 0 36px 44px 36px;
  `}
`;

export const ContainerBackgroundImage = styled.img`
  object-fit: cover;
  height: 100%;
  max-height: 25vh;
  width: 100%;
  border-radius: 0 0 4px 4px;

  ${media.desktop`
    border-radius: 4px 0 0 4px;
    max-height: 100%;
  `}
`;
